import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/IndexView.vue'
import Verification from '../views/VerificationView.vue'
import NotFound from '../views/NotFoundView.vue'

const routes = [
    {
      path: '/',
      name: 'index',      
      component: Index,
      meta: { title: 'central-clearance' }
    },
    { 
      path: '/verification/:database/:transactionid',
      name: "Verification",
      component: Verification,
      props: true,
      meta: { title: 'central-clearance verification' }
    },
    {
        /* Wildcard path to catch other paths */
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: NotFound,
        meta: { title: 'central-clearance 404' }
    }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
