import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import Lara from '@/presets/lara';      
import 'primevue/resources/themes/aura-light-green/theme.css';
import './assets/main.css';

const app = createApp(App)
/* Router has a beforeEnter guard dependent on Pinia being instantiated first, which is why this import statement is here. */
import router from './router'
app.use(PrimeVue, {
    unstyled: true,
    pt: Lara                            
})
app.use(router)
app.mount('#app')