<template>
  <section v-if="errored">
      <div class="wrapper">
        <h1 class="title">RECORD NOT FOUND</h1>
        <br>
        <h4>INVALID QRCODE</h4>
      </div>
  </section>
  <section v-else>
    <div v-if="loading">Loading...</div>
    <div class="card center_card">
    <h2 class="card-label-header">CENTRAL RECORDS SECTION</h2>
    <h2 class="card-label-header">CITY GOVERNMENT OF PARAÑAQUE</h2>
    <h2 class="card-label-sub-header">VERIFIED BY CLEARANCE CENTRAL</h2>
    <h3 class="card-control-number"> CONTROL NUMBER: {{ control_number.toLocaleUpperCase() }}</h3>
    <br>
    <div class="card-img-holder">
      <img v-bind:src="'data:image/gif;base64,'+photo" alt="Applicant Photo">
    </div>
    
    <h3 class="card-label-value">{{ first_name.toLocaleUpperCase() }} {{ middle_name.toLocaleUpperCase() }} {{ last_name.toLocaleUpperCase() }}</h3>

    <span class="card-label-caption">DATE ISSUED:</span>
    <p class="card-label-description"> {{ formatDate(application_date) }}</p>

    <span class="card-label-caption">VALID UNTIL:</span>
    <p class="card-label-description"> {{ formatDate(expiration_date) }}</p>

    <span class="card-label-caption">APPLICATION ID NUMBER:</span>
    <p class="card-label-description"> {{ atuid.toUpperCase() }}</p>

    <span class="card-label-caption">ADDRESS:</span>
    <p class="card-label-description"> {{ titleCase(complete_address) }}</p>

    <span class="card-label-caption">DATE OF BIRTH:</span>
    <p class="card-label-description"> {{ formatDate(date_of_birth) }}</p>
  
    <!-- <span class="card-label-caption">PLACE OF BIRTH</span>
    <p class="card-label-description"> {{ date_of_birth.toLocaleUpperCase() }}</p> -->

    <span class="card-label-caption">GENDER:</span>
    <p class="card-label-description"> {{ titleCase(gender) }}</p>

    <span class="card-label-caption">CIVIL STATUS:</span>
    <p class="card-label-description"> {{ titleCase(civil_status) }}</p>

    <span class="card-label-caption">CITIZENSHIP:</span>
    <p class="card-label-description"> {{ titleCase(citizenship) }}</p>    
  </div>
  </section>
</template>

<style>
  body {
    width: 100%;
    min-height: 100vh;
    display: relative;
    margin: 0;
    padding: 0;
    background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h4 {
    color: #f1f1f1;
    font-family: arial;
    font-weight: 300;
    font-size: 16px;
  }
</style>

<script>
import axios from 'axios';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Fieldset from 'primevue/fieldset';
import { useRoute } from 'vue-router';
export default {
  name: 'Verification',
  mounted() {
     const route = useRoute();
     console.log("route", route.params);
  },
  components: {
      Button,
      Card,
      Fieldset
  },
  data() {
    return {
      loading: true,
      errored: false,
      first_name: '',
      middle_name: '',
      last_name: '',
      photo: '',
      purpose: '',
      range: '',
      application_date: '',
      expiration_date: '',
      gender: '',
      citizenship: '',
      complete_address: '',
      civil_status: '',
      control_number: '',
      date_of_birth: '',
      atuid: '',
    }      
  },
  compatConfig: {
    MODE: 2 
  },
  methods: {
    getMessage() {
      var currentUrl = window.location.pathname;
      // console.log(`Current URL => ${currentUrl}`);
      const path = 'https://api.clearance-central.com/' + currentUrl;
      axios.get(path)
        .then((res) => {
          this.first_name = res.data.first_name;
          this.middle_name = res.data.middle_name;
          this.last_name = res.data.last_name;
          this.photo = res.data.photo;
          this.purpose = res.data.purpose;
          this.range = res.data.range;
          this.application_date = res.data.application_date;
          this.expiration_date = res.data.expiration_date;
          this.gender = res.data.gender;
          this.citizenship = res.data.citizenship;
          this.complete_address = res.data.complete_address;
          this.civil_status = res.data.civil_status;
          this.control_number = res.data.control_number;
          this.date_of_birth = res.data.date_of_birth;
          this.atuid = res.data.atuid;
        })
        .catch((error) => {
          this.errored = true;
          console.log(error);
        })
        .finally(() => this.loading = false);
    },
    titleCase(a) {
      return a.toLowerCase().replace(/(^|\s|-|\')(\w)/g, function (match) {
        return match.toUpperCase();
      });
    },
    formatDate(a) {
            const date = new Date(a);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
  },
  created() {
    this.getMessage();
    document.title = this.$route.meta.title;
  },
  props: {
    database: { type: String, required: true },
    transactionid: { type: String, required: true }
  },
};
</script>