<template>
  <div class="wrapper">
    <h1 class="title">404 NOT FOUND</h1>
    <br>
    <h4>THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST</h4>
  </div>
</template>

<style>
  @media (min-width: 1024px) {
    .about {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  body {
    width: 100%;
    min-height: 100vh;
    display: relative;
    margin: 0;
    padding: 0;
    background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h4 {
    color: #f1f1f1;
    font-family: arial;
    font-weight: 300;
    font-size: 16px;
  }
  .button {
    display: block;
    margin: 20px 0 0;
    padding: 15px 30px;
    background: #22254C;
    color: white;
    font-family: arial;
    letter-spacing: 5px;
    border-radius: .4rem;
    text-decoration: none;
    box-shadow: 0 0 15px #22254C;
  }
</style>