<template>
  <div class="wrapper">
    <h1 class="title">CLEARANCE CENTRAL</h1>
    <br>
    <h4>LOCAL CLEARANCE CERTIFICATE VERIFICATION SITE</h4>
    <br>
    <Button v-on:click="visible=!visible" type="button">SCAN CLEARANCE CERTIFICATE QRCODE</button>
    <Dialog v-model:visible="visible" modal header="QRCODE SCANNER" :style="{ width: '25.5rem' }">
      <div class="panel-radius" :style="{ width: '22.4rem' }">
        <!-- <qrcode-stream v-show="visible" @decode="onDecode"> 
          <b>stuff here overlays the camera stream</b> 
        </qrcode-stream> -->
        <qrcode-stream
          v-show="visible"
          :constraints="selectedConstraints"
          :track="trackFunctionSelected.value"
          :formats="selectedBarcodeFormats"
          @error="onError"
          @detect="onDetect"
          @camera-on="onCameraReady"
        />
      </div>
      <div class="flex justify-center gap-2">
          <p class="error">{{ error }}</p>
          <p class="decode-result"><b>{{ result }}</b></p>
      </div>
   </Dialog>
    <!-- <qrcode-drop-zone></qrcode-drop-zone> -->
    <!-- <qrcode-capture></qrcode-capture> -->
  </div>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
    name: 'Index',
    components: {
    Button,
    Dialog,
  },
  data() {
    return {
      visible: false,
      qrcodeesult: ''
    }
  },
  methods: {
    onDecode (decodedString) {
      this.qrcodeesult = decodedString;
    }
  }
}
</script>

<script setup>
import { ref, computed } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { useRoute, useRouter } from 'vue-router'
/*** detection handling ***/
const router = useRouter()
const result = ref('')

function onDetect(detectedCodes) {
  // console.log(detectedCodes)
  var a = JSON.stringify(detectedCodes.map((code) => code.rawValue));
  if (a != null) {
    var b = a.substring(1, a.length - 1);
    var c = [];
    if (b.includes("xidmo.com/")) {
      result.value = a.substring(2, a.length - 2);
      // window.open(result.value, '_blank');
      c = result.value.split("/");
      if (c.length == 3) {
        // console.log(c[2].toString());
        router.push('/verification/clearance/' + c[2].toString());
      }
    }
    else {
      result.value = "THIS QRCODE IS NOT VALID, PLEASE TRY AGAIN!";
    }
  }
  // result.value = JSON.stringify(detectedCodes.map((code) => code.rawValue))
}

/*** select camera ***/

const selectedConstraints = ref({ facingMode: 'environment' })
const defaultConstraintOptions = [
  { label: 'rear camera', constraints: { facingMode: 'environment' } },
  { label: 'front camera', constraints: { facingMode: 'user' } }
]
const constraintOptions = ref(defaultConstraintOptions)

async function onCameraReady() {
  // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
  // camera access permission. `QrcodeStream` internally takes care of
  // requesting the permissions. The `camera-on` event should guarantee that this
  // has happened.
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')

  constraintOptions.value = [defaultConstraintOptions, videoDevices.map(({ deviceId, label }) => ({
      label: `${label} (ID: ${deviceId})`,
      constraints: { deviceId }
    }))
  ]

  error.value = ''
}

/*** track functons ***/

function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = 'red'

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 5
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#35495e'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])

/*** barcode formats ***/

const barcodeFormats = ref({
  aztec: false,
  code_128: false,
  code_39: false,
  code_93: false,
  codabar: false,
  databar: false,
  databar_expanded: false,
  data_matrix: false,
  dx_film_edge: false,
  ean_13: false,
  ean_8: false,
  itf: false,
  maxi_code: false,
  micro_qr_code: false,
  pdf417: false,
  qr_code: true,
  rm_qr_code: false,
  upc_a: false,
  upc_e: false,
  linear_codes: false,
  matrix_codes: false
})
const selectedBarcodeFormats = computed(() => {
  return Object.keys(barcodeFormats.value).filter((format) => barcodeFormats.value[format])
})

/*** error handling ***/

const error = ref('')

function onError(err) {
  error.value = `[${err.name}]: `

  if (err.name === 'NotAllowedError') {
    error.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value +=
      'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }
}
</script>

<style>
  @media (min-width: 1024px) {
    .about {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  body {
    width: 100%;
    min-height: 100vh;
    display: relative;
    margin: 0;
    padding: 0;
    background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h4 {
    color: #f1f1f1;
    font-family: arial;
    font-weight: 300;
    font-size: 16px;
  }
  .button {
    display: block;
    margin: 20px 0 0;
    padding: 15px 30px;
    background: #22254C;
    color: white;
    font-family: arial;
    letter-spacing: 5px;
    border-radius: .4rem;
    text-decoration: none;
    box-shadow: 0 0 15px #22254C;
  }
</style>